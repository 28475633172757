.earn-main-page h2 {
    font-size: 40px;
    font-weight: 700;
    padding: 0px 0px 30px;
    color: var(--color) !important;
}

.earn-main-page-table {
    padding: 40px 60px;
}

.earn-main-page-table td {
    font-weight: 600;
    font-size: 15px;
}

.collection-image-div img {
    width: 50px;
    height: 50px;
}

.collection-image-div-outer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 900;
    color: var(--color);
}

.tvlimg-outer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.tvlimg img {
    width: 18px;
}

.tvlimg-outer.center-align {
    justify-content: center;
}

.tvlimg-outer.right-align {
    justify-content: flex-end;
}


/* shalomy */

.earn-main-page-table th .table-title {
    background: #0637b0;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    border-radius: 30px;
    text-align: center;
    padding: 16px 0;
    margin: 0 10px !important;
}

.earn-main-page-table th.rollno {
    background-color: transparent !important;
}

.earn-main-page-table th {
    border-bottom: 0;
}

.totalvaluespan {
    font-size: 12PX !important;
    font-weight: 900 !important;
    color: #929498 !important;
}

.earn-main-page-table td.rollno {
    border-bottom: 0;
}

/* shalomy */