.explore-body-content>.MuiBox-root {
    position: relative;
}

.flex-box-all-chains-view-all.MuiBox-root.css-0 {
    border-bottom: 1px solid rgba(18, 18, 18, 0.08);
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

.collections-outers span{
    font-size: 15px !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: capitalize;
    color: #000;
    font-weight: 600;
    }

.flex-box-all-chains-view-all {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.flex-box-all-chains-view-all.flex-box-all-chains-view-all-explore {
    border-bottom: none !important;
    position: relative;
}

.flex-box-all-chains-view-all.flex-box-all-chains-view-all-explore {
    padding: 0px !important;
}

.flex-box-all-chains-view-all .MuiTabs-root.outer-tabs .MuiTabs-scroller .MuiTabs-flexContainer {
    background-color: rgba(18, 18, 18, 0.04);
    border-radius: 12px;
    padding: 2px;
    box-sizing: border-box;
    margin: 0px;
    overflow: hidden;
    min-height: 48px;
}

.flex-box-all-chains-view-all .MuiTabs-root.outer-tabs .MuiTabs-scroller .MuiTabs-flexContainer button.Mui-selected {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    color: #000;
    width: 116px;
    text-transform: capitalize;
}

.MuiTabs-root.inner-tabs.inner-tabs-trend .MuiTabs-flexContainer,
.MuiTabs-root.inner-tabs.inner-tabs-top .MuiTabs-flexContainer {
    background-color: rgba(18, 18, 18, 0.04);
    border-radius: 12px;
    padding: 2px;
    box-sizing: border-box;
    margin: 0px;
    overflow: hidden;
    min-height: 48px;
}

.MuiTabs-root.inner-tabs.inner-tabs-trend .MuiTabs-flexContainer button.Mui-selected,
.MuiTabs-root.inner-tabs.inner-tabs-top .MuiTabs-flexContainer button.Mui-selected {
    background-color: rgb(255, 255, 255) !important;
    border-radius: 10px;
    color: rgb(18, 18, 18);
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px);
}

.MuiTabs-root.inner-tabs.inner-tabs-top button.MuiButtonBase-root,
.MuiTabs-root.inner-tabs.inner-tabs-trend button.MuiButtonBase-root {
    padding-left: 0px;
    padding-right: 0px;
    min-width: 45px;
    z-index: 1;
    border: none;
    border-radius: 10px;
    color: rgb(84, 84, 84) !important;
    background-color: transparent !important;
    margin: 0px;
    padding: 0px 12px;
    transition: all 0.3s ease 0s;
}

span.MuiTabs-indicator {
    background: none !important;
}

.all-chains-view-all .MuiSelect-select {
    text-align: left;
    background-color: #f6f6f6;
    padding: 14.5px 14px;
    border-radius: 12px;
}

.MuiFormControl-root label.MuiFormLabel-root {
    color: #373737;
    font-size: 16px;
    font-weight: 500;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
}

a.view-all-btn-style {
    display: flex;
    text-decoration: none;
    white-space: nowrap;
    padding: 11px 24px;
    font-size: 16px;
    background-color: rgba(18, 18, 18, 0.04);
    height: 36px;
    align-items: center;
    color: #373737;
    border-radius: 12px;
    height: 52px;
}

.all-chains-view-all {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 30%;
}

.explore-ranking-Table {
    position: relative;
    padding-right: 0px !important;
}

.collections-img img {
    width: 70px;
    height: 70px;
    border-radius: 10px;
}

.firstContain .MuiGrid-root.MuiGrid-item {
    padding-left: 0;
}

.firstContain {
    border-bottom: 1px solid #1212121f !important;
    margin-bottom: 20px;
}

.firstContain .MuiGrid-root.MuiGrid-item {
    padding-left: 0;
}
.rankingTheads {
    /* padding-left: 1rem !important; */
    padding-bottom: 0.75rem !important;
    padding-top: 0 !important;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    text-transform: capitalize;
    color: var(--color);
    font-weight: 600;
}

.rankingThead {
    /* padding-left: 1rem !important; */
    padding-bottom: 0.75rem !important;
    padding-top: 0 !important;
    color: rgba(0, 0, 0, 0.6);
}

.bodyRow {
    cursor: pointer;
}

.bodyRow {
    padding: 15px 0px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.bodyRow:hover {
    background-color: rgba(18, 18, 18, 0.04);
    border-radius: 10px;
}

.MuiGrid-root.MuiGrid-item {
    justify-content: flex-start;
}

.collections-outer {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 24px;
}

.ranking,.volume-rate {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 16px;
    color: var(--color);
    font-weight: 600;
}

.ranking {
    padding-left: 0 !important;
    padding-top: 0 !important;
}

.collections-outer span {
    font-size: 16px;
    text-transform: capitalize;
    color: var(--color);
    font-weight: 600;
}

.Table-overflow.mobile-response {
    display: none;
}

.MuiTabs-root.inner-tabs.inner-tabs-trend {
    position: absolute;
    top: 0px;
    left: 40%;
    margin-left: 34px;
}

.flex-box-all-chains-view-all .MuiTabs-root.outer-tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
    color: #5e5e5e;
    z-index: 1;
    border: none;
    border-radius: 10px;
    color: rgb(84, 84, 84);
    background-color: transparent;
    margin: 0px;
    padding: 0px 12px;
    transition: all 0.3s ease 0s;
    text-transform: capitalize;
}

.Collection-table-image-hover {
    display: flex;
    align-items: center;
    gap: 3px;
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
}

.Collection-table-image-hover img {
    width: 70px;
}

.sm-d-block {
    color: #000;
    display: none;
}

.container.home_container.topCollectionsBlock .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-sm-6.MuiGrid-grid-md-6.MuiGrid-grid-lg-6.MuiGrid-grid-xl-6 {
    padding-left: 40px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .rankingThead {
        padding-bottom: 0.75rem !important;
        padding-top: 0 !important;
        color: rgba(0, 0, 0, 0.6);
        text-align: center;
    }

    .MuiGrid-root.MuiGrid-item {
        justify-content: center;
    }

    .MuiTabs-root.inner-tabs.inner-tabs-trend {
        position: absolute;
        top: 0px;
        left: 38%;
        margin-left: 34px;
    }

}

@media (max-width: 991.98px) {
    .padding-none-lr .MuiBox-root {
        padding: 24px 10px;
    }

    .flex-box-all-chains-view-all .MuiTabs-root.outer-tabs .MuiTabs-scroller .MuiTabs-flexContainer button.Mui-selected {
        width: auto !important;
    }

    .flex-box-all-chains-view-all.MuiBox-root .outer-tabs {
        overflow: visible !important;
        width: max-content;
        justify-content: center;
    }

    .flex-box-all-chains-view-all.MuiBox-root {
        display: flex;
        flex-direction: column;
        gap: 19px;
    }

    .all-chains-view-all {
        margin-top: 0;
    }

    .explore-ranking-Table a.view-all-btn-style {
        padding: 0.625rem 0.75rem;
        height: 53px !important;
    }

    .padding-none-lr {
        padding: 0 !important;
    }

    .padding-none-lr .MuiBox-root {
        padding: 24px 10px;
    }

    .Table-overflow.desktop {
        display: none;
    }

    .firstContain {
        width: auto !important;
    }

    /* .rankingThead {
        text-align: center;
    } */
    .sm-d-block {
        text-align: center;
    }

    .bodyRow {
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        justify-content: space-between;
    }

    .collections-outer {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 10px;
    }

    .collections-img img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
    }

    .collections-outer span {
        font-size: 13px !important;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .volume-rate.ranking {
        font-size: 11px;
        display: block;
        margin-top: 40px;
        margin-left: 30px;
    }

    .Table-overflow.mobile-response {
        display: block;
    }

    .all-chains-view-all {
        width: 50% !important;
    }

    .MuiTabs-root.inner-tabs.inner-tabs-trend {
        position: absolute;
        left: 58%;
        margin-left: 0px;
        width: 40%;
        top: 10%;
    }

    .collections-outer span {
        font-size: 12px !important;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .collections-outer-span-mobile-two span p {
        font-size: 12px;
        margin: 0px;
    }

    .collections-outer-span-mobile-two {
        display: flex;
        align-items: center;
    }

    .container.home_container.topCollectionsBlock .MuiBox-root.css-19kzrtu {
        padding: 0px;
    }

    .sm-d-block {
        color: #000;
        display: block;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper {
        bottom: 0 !important;
        width: 100% !important;
        max-height: none !important;
    }
}

@media (max-width: 767.98px) {

    .padding-none-lr .MuiBox-root {
        padding: 24px 10px;
    }

    .flex-box-all-chains-view-all .MuiTabs-root.outer-tabs .MuiTabs-scroller .MuiTabs-flexContainer button.Mui-selected {
        width: auto !important;
    }

    .flex-box-all-chains-view-all.MuiBox-root .outer-tabs {
        overflow: visible !important;
        width: max-content;
        justify-content: center;
    }

    .flex-box-all-chains-view-all.MuiBox-root {
        display: flex;
        flex-direction: column;
        gap: 19px;
    }

    .all-chains-view-all {
        margin-top: 0;
    }

    .explore-ranking-Table a.view-all-btn-style {
        padding: 0.625rem 0.75rem;
        height: 53px !important;
    }

    .padding-none-lr {
        padding: 0 !important;
    }

    .padding-none-lr .MuiBox-root {
        padding: 24px 10px;
    }

    .Table-overflow.desktop {
        display: none;
    }

    .firstContain {
        width: auto !important;
    }

    /* .rankingThead {
        text-align: center;
    } */
    .sm-d-block {
        text-align: center;
    }

    .bodyRow {
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        justify-content: space-between;
    }

    .collections-outer {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 10px;
    }

    .collections-img img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
    }

    .collections-outer span {
        font-size: 10px !important;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .volume-rate.ranking {
        font-size: 11px;
        display: block;
    }

    div#table123 {
        display: flex;
        flex-wrap: nowrap;
        width: 205%;
        gap: 20px;
        justify-content: space-between;
        overflow: hidden;
    }

    .Table-overflow.mobile-response {
        overflow-x: scroll;
        margin-bottom: 40px;
    }

    .Table-overflow.mobile-response {
        display: block;
    }

    .all-chains-view-all {
        width: 100% !important;
    }

    .MuiTabs-root.inner-tabs.inner-tabs-trend {
        top: 5px;
        position: relative;
        left: 0px;
        margin-left: 0px;
        width: 100%;
    }

    .collections-outer-span-mobile-two span p {
        font-size: 12px;
        margin: 0px;
    }

    .collections-outer-span-mobile-two {
        display: flex;
        align-items: center;
    }

    .container.home_container.topCollectionsBlock .MuiBox-root {
        padding: 0px;
    }

    .sm-d-block {
        color: #000;
        display: block;
    }

    .explore-ranking-Table.MuiBox-root {
        padding: 0px;
    }

    .explore-ranking-Table {
        padding: 0 !important;
    }
}