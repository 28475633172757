 
@font-face {
    font-family: 'ApercuBold';
    src: url('../fonts/Apercu-Bold.eot');
    src: url('../fonts/Apercu-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Apercu-Bold.woff2') format('woff2'),
        url('../fonts/Apercu-Bold.woff') format('woff'),
        url('../fonts/Apercu-Bold.ttf') format('truetype'),
        url('../fonts/Apercu-Bold.svg#Apercu-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ApercuLight';
    src: url('../fonts/Apercu-Medium.eot');
    src: url('../fonts/Apercu-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Apercu-Medium.woff2') format('woff2'),
        url('../fonts/Apercu-Medium.woff') format('woff'),
        url('../fonts/Apercu-Medium.ttf') format('truetype'),
        url('../fonts/Apercu-Medium.svg#Apercu-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ApercuRegular';
    src: url('../fonts/Apercu-Regular.eot');
    src: url('../fonts/Apercu-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Apercu-Regular.woff2') format('woff2'),
        url('../fonts/Apercu-Regular.woff') format('woff'),
        url('../fonts/Apercu-Regular.ttf') format('truetype'),
        url('../fonts/Apercu-Regular.svg#Apercu-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ApercuMono';
    src: url('../fonts/Apercu-Mono.eot');
    src: url('../fonts/Apercu-Mono.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Apercu-Mono.woff2') format('woff2'),
        url('../fonts/Apercu-Mono.woff') format('woff'),
        url('../fonts/Apercu-Mono.ttf') format('truetype'),
        url('../fonts/Apercu-Mono.svg#Apercu-Mono') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ApercuThin';
    src: url('../fonts/Apercu-Light.eot');
    src: url('../fonts/Apercu-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Apercu-Light.woff2') format('woff2'),
        url('../fonts/Apercu-Light.woff') format('woff'),
        url('../fonts/Apercu-Light.ttf') format('truetype'),
        url('../fonts/Apercu-Light.svg#Apercu-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ApercuItalic';
    src: url('../fonts/Apercu-Italic.eot');
    src: url('../fonts/Apercu-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Apercu-Italic.woff2') format('woff2'),
        url('../fonts/Apercu-Italic.woff') format('woff'),
        url('../fonts/Apercu-Italic.ttf') format('truetype'),
        url('../fonts/Apercu-Italic.svg#Apercu-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
 
