.stk-banner {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.stk-img {
    width: 150px;
    height: 150px;
    border-radius: 15px;
}

.staking__title {
    position: relative;
}

.profile-img {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.form-group {
    font-family: "ApercuBold";
    color: var(--color);
    font-size: 40px;
    font-weight: 600;
    margin: 50px 20px;
    text-align: center;
}

.form-control-nft-name,
.input-field {
    font-family: "ApercuBold";
    color: var(--color);
    font-size: 18px;
    font-weight: 400;
    margin: 20px;
    text-align: left;
}

.input-field input {
    font-family: "ApercuBold";
    color: var(--color);
    font-size: 20px;
    /* font-weight: 400; */
    background-color: transparent;
    border: 1px solid var(--color);
    min-width: 360px;
    padding: 14px;
    border-radius: 15px;
}

.input-field.xl input {
    border-radius: 15px 0 0 15px;
    min-width: 284px;
}

.sktbtn {
    text-align: center;
    margin: 20px;
}

.sktbtn button,
.sktbtn button:hover {
    color: #fff;
    padding: 8px;
    border-radius: 8px;
}

.drop-down .MuiSelect-select {
    background-color: transparent;
    border: 1px solid var(--color);
    color: var(--color);
    padding: 17.5px;
    border-radius: 15px;

}

.drop-down.xl .MuiSelect-select {
    border-left: 1px solid #fff;
    border-radius: 0px 15px 15px 0px;
}


.drop-down svg,
.dateTimePicker svg {
    fill: var(--color) !important;
}

ul.MuiList-root {
    background: var(--opp) !important;
    color: var(--color);
}

.drop-down {
    margin: 20px;
    width: 100%;
}

.dateTimePicker {
    width: 360px;
    background: var(--opp);
    border: 1px solid var(--color);
    margin: 20px;
    border-radius: 15px;
}

.dateTimePicker input {
    color: var(--color);
    padding: 14px;
}

.dateTimePicker .MuiStack-root {
    padding-top: 0px !important;

}

.pa-stk {
    font-size: 12px;
    padding: 5px;
    opacity: 70%;
}

.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 10px;
    border-radius: 15px;
    margin: 25px;
    border: 1px solid #ffffff65;
}



.modal-header {
    justify-content: center;
}

.form-control-nft-name p {
    font-size: 15px;
}

.staking-wizard {
    background: #043ebb;
    padding: 20px;
    border-radius: 50px;
    margin-top: 10px;
}

.staking-wizard p {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    max-width: 750px;
    margin: 0 auto;
}

.let-magic {
    margin-top: 15px;
}

.let-magic p {
    text-align: center;
    color: var(--color) !important;
    font-size: 25px;
    font-weight: 700;
}

.contain-width {
    max-width: 1440px;
    margin: 0 auto;
}

.let-magic p span {
    color: #043ebb;
}

.need-help p {
    color: var(--color) !important;
    font-size: 25px;
    font-weight: 700;
}

.magic-clown {
    margin-top: -165px;
}

.logo-part {
    text-align: center;
    margin-top: -35px;
}

/* 
.opacity {
    backdrop-filter: blur(14px);
    opacity: 12%;
} */


@media (max-width:767.98px) {

    .header_attension_dtls {
        flex-direction: column-reverse;
        align-items: end !important;
        padding: 10px 3px !important;
    }

    .header_attension_msg {
        max-width: 90% !important;
        margin: 0 auto !important;
    }

    .staking-wizard {
        max-width: 298px;
        margin: 10px auto;
    }

    .input-field input,
    .dateTimePicker,
    .drop-down .MuiFormControl-root {
        min-width: 275px;
    }

    .input-field.drop-down.xl.day-drop input {
        min-width: 129px !important;
    }

    .input-field.xl input {
        /* min-width: 284px; */
        width: 145px;
    }

    .dateTimePicker {
        width: 275px;
    }

    .drop-down.xl .MuiFormControl-root {
        min-width: auto !important;
    }

    .staking-wizard p {
        font-size: 15px;
    }

    .magic-clown img {
        width: 100%;
    }
}

@media (max-width:575.98px) {
    .input-field.model input {
        min-width: 245px !important;
    }

    .w-50 {
        width: auto !important;
    }
}

@media(max-width:991.98px) {
    .need-help {
        padding: 0 15px;
    }

    .magic-clown {
        margin-top: 0;
    }
}

@media(max-width:767.98px) {
    .drop-down {
        width: 100%;
    }
}

@media(min-width:768px) and (max-width:991.98px) {
    .drop-down {
        width: 70% !important;
    }
}