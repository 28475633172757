.ProfileContent {
    background: #fff;
    margin: 0;
    border-radius: 0;
    overflow: hidden;
}

.profile-details-name h2 {
    margin: 0px;
    font-size: 30px;
    color: #000;
}

.profile-details {
    padding: 0 0 50px;
}

.profile-details-acc-date, .profile-details-name {
    /* padding-left: 40px; */
    text-align: left;
}

.profile-details-acc-date {
    margin-bottom: 50px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 80px;
}

.profile-details-acc-date span {
    display: inline-block;
    color: #000;
    font-size: 16px;
}

.profile-details-acc-date span:nth-child(2) {
    margin-left: 20px;
}

.ProfileContent .MuiInputBase-root:hover fieldset, .ProfileContent .MuiInputBase-root:hover input{border: 1px solid #000 !important;}

.search-box-filter {
    height: 56px;
    border: 1px solid #ccc;
    margin-left: 0px;
}

.search-box-filter input {
    height: 34px;
    padding-left: 0px !important;
}

.search-box-filter .MuiInputBase-root:hover input {
    border: none !important;
}

div#filter-part {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(18, 18, 18, 0.04) !important;
    border-radius: 12px !important;
}

div#filter-part svg {
    font-size: 30px;
    color: #000;
}


div#filter-part svg {
    font-size: 30px;
    color: #000;
}

div#filter-sidebar h3 {
    text-align: left;
    color: #000;
    font-size: 18px;
}

div#filter-sidebar {
    margin-top: 50px;
}

div#filter-sidebar .MuiBox-root {
    display: flex;
    gap: 10px;
    align-items: center;
}

div#filter-sidebar .MuiBox-root > span {
    font-size: 20px;
}

button#apply-common {
    text-transform: none;
    margin-top: 15px;
    box-shadow: none;
}

#containwidth{gap: 0px !important;}

.profile-image-banner .profile-img img {
    width: 150px;
}

.profile-image-banner .profile-img {
    width: 150px;
    border-radius: 50%;
    border: 5px solid #ede7e7;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fbfbfb;
    display: none;
}

.profile-img-inner {
    overflow: hidden;
    border-radius: 50%;
    height: 150px;
    height: 168px;
    width: 168px;
    border: 6px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 16px;
    border: 4px solid rgb(255, 255, 255);
    border-radius: 50%;
    position: relative;
    z-index: 1;
    background: rgb(255, 255, 255);
}

/* .profile-image-banner .profile-img {
    padding: 40px 80px;
} */

.profile-banner, .profile-banner .profile-banner-inner {
    height: 400px;
    overflow: hidden;
    position: relative;
}

.profile-banner img {
    width: 100%;
    height: 400px;
    /* object-fit: cover; */
}

.profile-img-outer {
    padding: 10px 40px;
    margin-top: -143px;
    position: relative;
}

#profile-setting-btn {
    padding: 10px 30px;
    background: #efefef;
    border: none;
    border-radius: .5rem;
    color: #444;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;
    box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
              -6px -6px 10px rgba(255, 255, 255, .5),
              6px 6px 8px rgba(255, 255, 255, .075),
              6px 6px 10px rgba(0, 0, 0, .15);
    text-transform: none;
    text-decoration: none;
}

.profile-img{position: relative;}

button#image-upload-button, button#image-upload-button-banner {
    position: absolute;
    background: #fff;
    min-width: 45px !important;
    border-radius: 50%;
    height: 45px;
    right: 0;
    bottom: 0;
    z-index: 999;
}

button#image-upload-button-banner {
    top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    display: none;
}

.profile-banner:hover button#image-upload-button-banner {
    display: flex !important;
}
.dot-drop-menu svg{
    font-size: 24px !important;
    }
    .dot-dropdown svg{
        margin-right: 10px;
    }
    .dot-drop-menu ul{
        margin-right: 20px;
        margin-top: 20px;
    }
    .demo-positioned-menu {
        top: 40px !important;
        border-radius: 12px !important;
    }
    .MuiPopover-paper.MuiMenu-paper{
        border-radius: 12px !important;
    }

    .search-box-filter .MuiInputBase-root {
        width: 100%;
    }
    
    .search-box-filter .MuiInputBase-root input {
        padding-left: 50px !important;
    }
    
    div#containwidth-pdt-collection-items h3 {
        margin: 20px 0;
    }
    .profile-img-inner img {
        height: 168px;
        width: 168px;
        object-fit: cover;
    }
    
    .SubPages ul{
    list-style: none;
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    padding: 0;
}
.SubPages ul li{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
}
li.ActiveMenu, li.menu:hover{
    background: rgb(90 84 84 / 12%) !important;
    border-radius: 9px;
}
li.ActiveMenu a, li.menu a:hover{
    color: rgb(18, 18, 18) !important;
}
li.menu{
    background: transparent;
    display: flex;
    padding: 8px 16px;
    width: fit-content;
    cursor: pointer;
}
li.menu a{
    color: rgb(84, 84, 84);
}
.offer-price{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3%;
    padding-left:40px;
}
.filter-list-flex{
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}
.filter-left-Align{
    -webkit-box-align: center;
    align-items: center;
    flex-shrink: 0;
    display: flex;
}
.total-item-result{
    margin-left: 16px;
    /* margin-right: 12px; */
}
.w-full{
    width: 60%;
    /* margin-left: 12px; */
}
.priceList {
    width: 250px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    padding-left: 16px !important;
}
.titles-tab{
    width: fit-content;
    padding-left: 16px !important;
}
.ProfileContent .tiles-block {
    border-radius: 12px !important;
}
.simple-select-form{
    border: 1px solid rgba(18, 18, 18, 0.12) !important;
    border-radius: 12px;
    min-height: fit-content;
    height: 49px !important;
    margin-bottom: -1px !important;
}
.priceList .MuiInputBase-formControl:hover{
    height: 49px !important;
    margin-bottom: -1px !important;
}
.ProfileContent.collection-detail-page .SubPages {
    margin: 0 40px;
    border-bottom: 1px solid #ececec;
    padding:0  0 1rem;
}
.ProfileContent.collection-detail-page li.menu {
    padding: 12px 24px;
}
  /* shalomy styles */

  .explore-notable-cllection-slide.nft{
    margin-top: 1.5rem;
  }

  .nft a.notable-collection-item {
    display: block;
    position: relative;
    text-decoration: none;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    box-shadow: none !important;
    border: 1px solid rgba(18, 18, 18, 0.1) !important;
    transition: none !important;
}

.explore-notable-cllection-slide .nft .item:hover{
    transition: none !important;
    transform: none !important;
    /* border: 1px solid rgba(18, 18, 18, 0.32); */
    transition: border 0.25s ease-in-out 0s !important;
    border-radius: 20px;
}
.nft .cover-photo-for-collection-detail{
    padding: 16px;
}
.nft a.notable-collection-item .cover-photo-for-collection-detail h4{
    height: 48px;
    padding: 0;
    max-width: 224px;
}
.nft .floor-vol-rate-block{
    display: none;
}
.nft .cover-photo-for-collection img {
    height: 302px;
}
.nft.explore a.notable-collection-item .cover-photo-for-collection-detail h4{
    height: auto;
    padding: 0;
    max-width: 224px;
}

.profile-img-outer {
    margin-top: -156px;
    display: inline-block;
}

.tiles-block {
    display: flex;
    /* padding: 5px 20px !important; */
    justify-content: space-between;
    border-radius: 20px !important;
}

.tile-design img {
    width: 30px;
}

.tile-design {
    width: 30%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    border: none;
    border-radius: 10px;
    color: rgb(84, 84, 84);
    background-color: transparent;
    margin: 0px;
    padding: 0px 12px;
    transition: all 0.3s ease 0s;
}

.product-item-img img {
    width: 100%;
    min-height: 337px;
}

div#containwidth-pdt-collection-items {
    gap: 0px;
    align-items: self-start;
}

div#containwidth-pdt-collection-items div#filter-id-pdts {
    padding: 0px;
    gap: 0px;
    margin-top: 50px;
}

div#containwidth-pdt-collection-items div#filter-id-pdts > .MuiGrid-root.MuiGrid-item {
    padding: 0 15px;
}

div#containwidth-pdt-collection-items div#filter-id-pdts .product-item {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 40px;
    box-shadow: -1px -1px 5px #ccc;
}

.pdt-name-price h4.pdt-name {
    margin: 0px;
    text-align: left;
    font-size: 18px;
    color: #757272;
}

span.pdt-price {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 15px;
    margin-top: 10px;
    color: #000;
}

.pdt-name-price {
    padding: 10px;
    padding-bottom: 20px;
}

div#containwidth-pdt-collection-items div#filter-id-pdts .product-item button {
    text-transform: none;
    font-size: 16px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    transform: translateY(100%);
}

div#containwidth-pdt-collection-items div#filter-id-pdts .product-item:hover button {
    transform: translateY(0);
}

div#containwidth-pdt-collection-items div#filter-id-pdts .product-item{cursor: pointer;}

.label-block-outer {
    font-size: 16px;
    color: #000;
    text-align: left;
}

.label-block-outer span {
    font-weight: 600;
    display: inline-block;
    margin-left: 5px;
}

.collection-detail-row {
    margin-bottom: 30px;
}

.label-block-outer.column-dir {
    flex-direction: column;
    display: flex;
    margin-top: 25px;
    margin-bottom: 15px;
}

.label-block-outer.column-dir span {
    margin-left: 0px;
    font-size: 22px;
}

.label-block-outer.column-dir label {
    text-transform: capitalize;
}

p.description-data {
    padding: 0 80px;
    margin-bottom: 0px;
    text-align: left;
    font-size: 16px;
    max-width: 900px;
    color: #000;
}

.productdetail-redirect{text-decoration: none !important;}

@media (max-width:991.98px){

    .profile-details-acc-date {
        flex-direction: column;
    }
    
    .profile-details-acc-dat-right a {
        display: inline-block;
    }
    
    .profile-details-acc-dat-right {
        width: 100%;
        margin-top: 20px;
    }
    
    .profile-details-acc-date, .profile-details-name {
        /* padding: 0px 0 0 20px; */
    }

    .ProfileContent{
        /* margin: 20px; */
        padding:1rem;
    }

    .profile-details-acc-date span:nth-child(2) {
        margin-left: 0;
    }

    .profile-img-outer {
        padding: 20px 40px 20px 15px;
    }

    .profile-details-acc-dat-left {
        width: 100%;
        display: flex;
        gap: 10px;
    }
    
    .profile-details-acc-dat-left span {
        font-size: 14px;
    }


    /* shalomy */

    .profile-banner img {
        width: 100%;
        height:auto;
    }
    .profile-img-inner img.img-display-before {
        width: 82px;
        height: 82px;
    }
    .profile-img-inner {
        height: 82px;
    }
    .profile-image-banner .profile-img{
        width: 82px;
        height: 82px;
    }

    button#image-upload-button, button#image-upload-button-banner {
        position: absolute;
        background: #fff;
        min-width: 4px !important;
        border-radius: 50%;
        height: 32px;
        right: -11px;
        bottom: -13px;
        z-index: 999;
    }
    .profile-banner, .profile-banner .profile-banner-inner {
        height: 245px;
        overflow: hidden;
        position: relative;
    }
    .profileFlex {
        padding: 0 !important;
        text-align: left;
        align-items: flex-start !important;
    }
    .profile-details-acc-dat-left {
        flex-direction: column;
    }
    .dashboard-page.profile-page-main .ProfileContent .SubPages {
        margin: 0;
    }
    .dashboard-page.profile-page-main .ProfileContent .SubPages li.menu a {
        color: rgb(84, 84, 84);
        white-space: nowrap;
    }
    #status-profileBox, .status-profileBox{
        display: none !important;
    }
    .status-profileBox.select-option{
        display: block !important;
    }
    .profile-details div#filter-part {
        height: 26px;
        padding: 0.75rem 8px;
    }
    .input1{
        display: none !important;
    }
    .input2{
        display: block;
    }
    .input2 svg{
        font-size: 24px !important;
    }
    .input2 .MuiSvgIcon-root-MuiSelect-icon{
        display: none;
    }
    div#filter-id\ filter-search-flex {
        margin: 10px 0;
    }
    .select-option-view-menu ul{
        position: relative;
        left: 0;
        width: 500px;
    }
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper
{opacity: 1;
    transform: none;
    transition: opacity 355ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 236ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 324px !important;
    left: auto !important;
    transform-origin: 150px 123.953px;
    width: -webkit-fill-available;
    max-width: none !important;
}
.status-profileBox .MuiSelect-select, .search-box-filter .MuiInputBase-root {
    min-height: 26px;
}
div#filter-sidebar {
    margin-top: 0;
}
.ProfileContent .product-col-block {
    padding-top: 0 !important;
    margin-top: 0;
}
.idContent{
    font-size: 10px;
}
.ProfileContent .pdt-name-price {
    overflow: hidden;
}
.pdt-name-price h4.pdt-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px !important;
}
.ratingDetails {
    gap: 13px;
    padding: 16px 10px !important;
}
.product-col-block{
padding-left: 10px !important;
}
div#containwidth-pdt-collection-items div#filter-id-pdts > .MuiGrid-root.MuiGrid-item{
    padding-right: 0 !important;
}
span.pdt-price {
    font-size: 13px !important;
}
.profile-details {
    padding-bottom: 0;
}
.ProfileContent .pdt-name-price {
    padding: 0px;
    padding-bottom: 0 !important;
}
.profile-img-inner {
    height: 82px !important;
    width: 82px !important;
}
.profile-img-outer{
    padding-left: 10px !important;
}
}

    /* shalomy */

@media (max-width:767.98px){
    .profile-banner img {
        width: 100%;
        height:auto;
    }
    .profile-img-inner img.img-display-before {
        width: 82px;
        height: 82px;
    }
    .profile-img-inner {
        height: 82px;
    }
    .profile-image-banner .profile-img{
        width: 82px;
        height: 82px;
    }

    button#image-upload-button, button#image-upload-button-banner {
        position: absolute;
        background: #fff;
        min-width: 4px !important;
        border-radius: 50%;
        height: 32px;
        right: -11px;
        bottom: -13px;
        z-index: 999;
    }
    .profile-banner, .profile-banner .profile-banner-inner {
        height: 245px;
        overflow: hidden;
        position: relative;
    }
    .profileFlex {
        padding: 0 !important;
        text-align: left;
        align-items: flex-start !important;
    }
    .profile-details-acc-dat-left {
        flex-direction: column;
    }
    .dashboard-page.profile-page-main .ProfileContent .SubPages {
        margin: 0;
    }
    .dashboard-page.profile-page-main .ProfileContent .SubPages li.menu a {
        color: rgb(84, 84, 84);
        white-space: nowrap;
    }
    #status-profileBox, .status-profileBox{
        display: none !important;
    }
    .status-profileBox.select-option{
        display: block !important;
    }
    .profile-details div#filter-part {
        height: 26px;
        padding: 0.75rem 8px;
    }
    .input1{
        display: none !important;
    }
    .input2{
        display: block;
    }
    .input2 svg{
        font-size: 24px !important;
    }
    .input2 .MuiSvgIcon-root-MuiSelect-icon{
        display: none;
    }
    div#filter-id\ filter-search-flex {
        margin: 10px 0;
    }
    .select-option-view-menu ul{
        position: relative;
        left: 0;
        width: 500px;
    }

.status-profileBox .MuiSelect-select, .search-box-filter .MuiInputBase-root {
    min-height: 26px;
}
div#filter-sidebar {
    margin-top: 0;
}
.ProfileContent .product-col-block {
    padding-top: 0 !important;
    margin-top: 0;
}
.idContent{
    font-size: 10px;
}
.ProfileContent .pdt-name-price {
    overflow: hidden;
}
.pdt-name-price h4.pdt-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px !important;
}
.ratingDetails {
    gap: 13px;
    padding: 16px 10px !important;
}
.product-col-block{
padding-left: 10px !important;
}
div#containwidth-pdt-collection-items div#filter-id-pdts > .MuiGrid-root.MuiGrid-item{
    padding-right: 0 !important;
}
span.pdt-price {
    font-size: 13px !important;
}
.profile-details {
    padding-bottom: 0;
}
.ProfileContent .pdt-name-price {
    padding: 0px;
    padding-bottom: 0 !important;
}
.profile-img-inner {
    height: 82px !important;
    width: 82px !important;
}
.profile-img-outer{
    padding-left: 10px !important;
}
}
@media (min-width: 768px) and (max-width: 991.98px){
    .profileFlex {
        padding: 0 20px !important;
    }
    .ProfileContent .SubPages {
        margin: 0 20px !important;
    }
}
@media (min-width: 992px) and (max-width: 1199.98px){
#status-profileBox, .status-profileBox{
    width: 109px !important;
}
.recentlyListed {
    width: 200px !important;
}
.status-profileBox.select-option{
    width: 100% !important;
}
}
@media (min-width: 992px) and (max-width: 1440.98px){


    div#filter-id {
        flex-wrap: wrap;
    }
    
    div#filter-id > div {
        max-width: 30% !important;
        width: 30% !important;
        flex-basis: 30%;
    }
    
    .search-box-filter input.MuiInputBase-input {
        padding-left: 50px !important;
    }

}



/* shalomy style */

Button.dotInfo{
    font-size: 30px;
    color: #000 !important;
}
.profileFlex{
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 40px;
}
.profile-details-acc-date{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.filter-left-Align, #filter-part{
    text-align: left;
}



.search-box-filter{
    border: 0;
}
.search-box-filter .MuiInputBase-root{
    padding: 0 !important;
}
.status-profileBox label#demo-multiple-checkbox-label {
    color: #373737;
    font-size: 16px;
    font-weight: 500;
    line-height: 1 !important;
}
.status-profileBox .MuiInputBase-root.MuiOutlinedInput-root:hover{
border-radius: 10px !important;
}
.search-box-filter input::-webkit-input-placeholder{ color:#0a0909 !important;}
.status-profileBox label#demo-simple-select-label{
    line-height: 1 !important;
}
.status-profileBox .MuiSelect-select, .search-box-filter .MuiInputBase-root{
    font-size: 16px;
    color: rgb(18, 18, 18);
    padding: 12px 12px 12px 16px;
    border-radius: 12px;
    border: 1px solid rgba(18, 18, 18, 0.12);
}
 ul.MuiList-root li {
    min-height: auto;
    font-size: 16px;
    font-weight: 600;
    padding: 0.75em;
    margin: 10px;
    border-radius: 10px;
    background-color: transparent;
}
ul.MuiList-root li a:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(18, 18, 18, 0.04) !important;
}
.recentlyListed {
    width: 100%;
}
.grid-Align-changes .tile-design {
    width: auto;
    height: auto;
    display: inline-block;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.grid-Align-changes .tile-design img {
    width: 20px;
    height: 20px;
}
.grid-Align-changes .tiles-block {
    display: flex;
    background: #e9e9e9;
    padding: 5px 20px;
    justify-content: space-between;
    border-radius: 9px;
    padding: 12px;
    background-color: rgba(18, 18, 18, 0.04) !important;
}
.ProfileContent .SubPages {
    margin: 0 40px;
    border-bottom: 1px solid #ececec;
    padding: 1rem 0;
}
.ProfileContent .profile-details-acc-date{
    margin-bottom: 0 !important;
}
.ProfileContent li.ActiveMenu, li.menu:hover {
    background: rgba(18, 18, 18, 0.04) !important;
    border-radius: 9px;
}
.ProfileContent li.menu:hover {
    background: rgba(18, 18, 18, 0.04) !important;
}
.ProfileContent .tiles-block {
    background: rgba(18, 18, 18, 0.04) !important;
}
.ProfileContent .tiles-block {
    padding: 4px;
}
.ProfileContent .tile-design img {
    width: 20px;
}
.ProfileContent .tiles-block {
    border-radius: 12px;
}
div#containwidth-pdt-collection-items div#filter-id-pdts > .MuiGrid-root.MuiGrid-item {
    padding: 0px 15px;
}
.ProfileContent div#filter-id-pdts {
    gap: 0;
}
div#containwidth-pdt-collection-items div#filter-id-pdts .product-item {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 40px;
    box-shadow: -1px -1px 5px #ccc;
}
.tile-design.active-tiles {
    border-radius: 10px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px);
    background-color: rgb(255, 255, 255);
}
.BuynOW-cart{
    display: flex;
    gap: 1px;
}
.BuynOW-cart Button{
    border: 0 !important;
    border-radius: 0 !important;
    height: 36px;
    background-color: rgb(32,129,226);
    flex-wrap: nowrap;
    text-transform: inherit;
}
.BuynOW-cart Button:hover{
    background-color: rgb(46,141,238);
    text-transform: inherit;
}
button.btnBuy{
    width: 90% !important;
}
button.btnCart{
    width: 10% !important;
}
.grid-pdt-block-specific > .MuiPaper-root:hover .BuynOW-cart {
    opacity: 1;
}
.BuynOW-cart {
    display: flex;
    gap: 1px;
    position: absolute;
    width: 100%;
    bottom: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
}
.grid-pdt-block-specific > .MuiPaper-root {
    position: relative;
}
.ProfileContent .pdt-name-price {
    padding: 0px;
    /* padding-bottom: 56px; */
}
.idContent{
    color: #000;
    padding: 0 8px;
    background-color: initial;
    /* box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
    -6px -6px 10px rgba(255, 255, 255, .5),
    6px 6px 8px rgba(255, 255, 255, .075),
    6px 6px 10px rgba(0, 0, 0, .15); */
    border-radius: 8px;
    border:1px solid rgba(18, 18, 18, 0.12);
    height: 28px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
}
.ratingDetails{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
}
.filter-options-collections div#filter-sidebar h3 {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
}
.filter-options-collections{
    padding: 0;
    padding-left: 20px;
}
.filter-options-collections fieldset.MuiOutlinedInput-notchedOutline {
    border:1px solid rgb(229,232,235) !important;
    border-radius: 12px !important;

}
.filter-options-collections fieldset.MuiOutlinedInput-notchedOutline:hover {
    border-color:rgb(18,18,18,0.32) !important;
}
.ProfileContent .MuiInputBase-root:hover fieldset, .ProfileContent .MuiInputBase-root:hover input {
    border: 1px solid rgb(18,18,18,0.32) !important;
    border-radius: 12px !important;
}
.ProfileContent ul.MuiList-root li span{
    padding-top: 0;
    padding-bottom: 0;
}
#status-profileBox label#demo-multiple-checkbox-label {
    color: #373737;
    font-size: 16px;
    font-weight: 500;
    line-height: 1 !important;
}
#status-profileBox .MuiInputBase-root.MuiOutlinedInput-root:hover{
border-radius: 10px !important;
}
.search-box-filter input::-webkit-input-placeholder{ color:#0a0909 !important;}
#status-profileBox label#demo-simple-select-label{
    line-height: 1 !important;
}
#status-profileBox .MuiSelect-select, .search-box-filter .MuiInputBase-root{
    font-size: 16px;
    color: rgb(18, 18, 18);
    padding: 12px 12px 12px 16px;
    border-radius: 12px;
    border: 1px solid rgba(18, 18, 18, 0.12);
}
.input1{
    display: block;
}
.input2{
    display: none;
}
/* shalomy style */


.profile-image-banner {
    position: relative;
    max-height: 320px;
    overflow: hidden;
}

.profile-banner-outer {
    height: 0px;
    padding-bottom: 25%;
}
.image-upload-container{
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.profile-banner-inner {
    position: absolute;
    inset: 0px;
    border: none;
}

.profile-img-outer {
    padding: 10px 40px;
    margin-top: 0;
    position: relative;
    margin-bottom: 16px;
    margin-top: -36px;
}
.profile-img-outer .image-upload-container{
position: relative;
}
.profile-img-inner img.img-display-before {
    width: 100%;
    /* height: 82px; */
}
.profile-img-inner {
    height: 168px;
    width: 168px;
}

.SubPages ul li a {
    text-decoration: none;
}

@media screen and (min-width: 600px){
    .profile-img-outer{
        margin-top: -86px;
    }
}
@media screen and (min-width: 1024px){
    .profile-img-outer{
        margin-top: -156px;
    }
    .input2{
        display: none !important;
    }

}




